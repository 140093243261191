import React from 'react'
import PropTypes from 'prop-types'
import { Container as GridContainer, Row, Col } from 'react-grid-system'
import Box from 'reusecore/src/elements/Box'
import Fade from 'react-reveal/Fade'
import Image from 'reusecore/src/elements/Image'
import Text from 'reusecore/src/elements/Text'
import Heading from 'reusecore/src/elements/Heading'
import Button from 'reusecore/src/elements/Button'
import FeatureBlock from '../../../components/FeatureBlock'
import Container from '../../../components/UI/Container'
import { TRANSACTIONS_FEATURE } from '../../../data/Crypto'
import { TransactionsWrapper, FeatureSection } from './transaction.style'

const TransactionsHistory = ({
  row,
  col,
  mainTitle,
  title,
  description,
  btnStyle,
  sectionSubTitle,
  cardArea,
  featureTitleStyle,
  featureDescriptionStyle
}) => {
  return (
    <TransactionsWrapper id="transactions">
      <Container>
        {/* 
        <div className="mainTitleContainer">
          <Heading as="h2" content="Le programme mobiSmile" {...mainTitle} />
        </div>
        */}

        <Box className="row" {...row}>
          <Box className="colleft" {...col} style={{ flexDirection: 'column' }}>
            <Text {...sectionSubTitle} />
            <FeatureBlock
              title={<Heading {...title} />}
              //description={<Text {...description} />}
              //button={<Button title="GET DOCS" {...btnStyle} />}
            />
          </Box>
          <Box className="colright" {...col} {...cardArea}>
            <FeatureSection>
              <GridContainer fluid>
                <Row>
                  <Col md={12}>
                    <Heading
                      as="h3"
                      content='1. Concours et paniers "découverte locale"'
                      {...featureTitleStyle}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="featureWrapper">
                      <Text
                        content="Chaque mois, plusieurs concours sont diffusés dans notre réseau de partenaires. Des paniers « découverte locale » sont mis en jeu, exposés en grande surface, et attribués aux heureux gagnants."
                        {...featureDescriptionStyle}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="featureWrapper">
                      <Text
                        content="Tous les produits composant le panier sont produits localement, l’objectif étant de faire découvrir au consommateur des nouveaux produits « locaux »."
                        {...featureDescriptionStyle}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Heading
                      as="h3"
                      content="2. Double visibilité des produits exposés"
                      {...featureTitleStyle}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="featureWrapper">
                      <Text
                        content="Pour que le consommateur découvre le produit, nous misons sur une exposition des produits en grande surface combinée à la diffusion d’un spot vidéo aux écrans-caisse."
                        {...featureDescriptionStyle}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Heading
                      as="h3"
                      content="3. Collection et valeur des échantillons"
                      {...featureTitleStyle}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="featureWrapper">
                      <Text
                        content="Aucune contrepartie financière n’est attendue. L’engagement des producteurs locaux est fondé exclusivement sur la fourniture, sur base mensuelle, d’échantillons du ou des produits à mettre en valeur."
                        {...featureDescriptionStyle}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="featureWrapper">
                      <Text
                        content="Aucun engagement dans le temps n’est attendu. L’engagement se fait uniquement sur une base trimestrielle."
                        {...featureDescriptionStyle}
                      />
                    </div>
                  </Col>
                </Row>
              </GridContainer>
            </FeatureSection>
          </Box>
        </Box>
      </Container>
    </TransactionsWrapper>
  )
}

// Transactions style props
TransactionsHistory.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  featureTitleStyle: PropTypes.object,
  featureDescriptionStyle: PropTypes.object
}

// Transactions default style
TransactionsHistory.defaultProps = {
  // Transactions section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  // Transactions section col default style
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, 1 / 2, 1 / 2, 1 / 2, 1 / 2],
    flexBox: true,
    alignSelf: 'center'
  },

  // Transactions section title default style
  mainTitle: {
    fontSize: ['34px', '36px', '40px', '46px', '50px'],
    lineHeight: ['40px', '42px', '50px', '60px', '65px'],
    fontWeight: '400',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mt: '120px',
    mb: '100px',
    maxWidth: ['100%', '100%', '100%', '100%', '100%'],
    textAlign: ['center', 'center']
  },
  title: {
    content: 'Créer le lien entre producteurs locaux et consommateurs finaux',
    fontSize: ['24px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '100%', '415px'],
    textAlign: ['left', 'left']
  },
  // Transactions section description default style
  description: {
    content:
      'You can trust us for any kind of services and some of the world class companies have also trusted us .',
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['30px', '30px', '40px', '40px', '55px'],
    textAlign: ['left', 'left'],
    maxWidth: ['100%', '100%', '100%', '100%', '430px']
  },
  sectionSubTitle: {
    content: 'Principe du programme',
    as: 'span',
    textAlign: 'left',
    fontSize: ['16px', '16px', '18px', '20px', '20px'],
    fontFamily: 'Poppins',
    fontWeight: '600',
    lineHeight: '27px',
    color: '#525f7f',
    textAlign: ['left', 'left']
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500'
  },
  featureTitleStyle: {
    fontSize: ['18px', '18px', '20px', '20px', '20px'],
    lineHeight: ['25px', '27px', '27px', '27px', '27px'],
    fontWeight: '500',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '10px',
    textAlign: ['left', 'left']
  },
  // Transactions section description default style
  featureDescriptionStyle: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '27px',
    textAlign: ['left', 'left']
  }
}

export default TransactionsHistory
