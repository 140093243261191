import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { Link } from 'gatsby'
import { Formik, Form, Field } from 'formik'
import { Row, Col } from 'react-grid-system'
import cn from 'classnames'
import axios from 'axios'
import * as Yup from 'yup'
import { Icon } from 'react-icons-kit'
import { cloud_upload } from 'react-icons-kit/ikons/cloud_upload'
import { clip } from 'react-icons-kit/ikons/clip'
import swal from '@sweetalert/with-react'
import ClipLoader from 'react-spinners/ClipLoader'

import Box from 'reusecore/src/elements/Box'
import Text from 'reusecore/src/elements/Text'
import Heading from 'reusecore/src/elements/Heading'
import Button from 'reusecore/src/elements/Button'
import FeatureBlock from '../../../components/FeatureBlock'
import Input from 'reusecore/src/elements/Input'
import Container from '../../../components/UI/Container'

import ContactFromWrapper, { SectionMainWrapper } from './contact.style'

const ContactSchema = Yup.object().shape({
  nomEntreprise: Yup.string().required('Ce champs est obligatoire'),
  nom: Yup.string().required('Ce champs est obligatoire'),
  email: Yup.string()
    .email('Email invalide')
    .required('Ce champs est obligatoire'),
  description: Yup.string().required('Ce champs est obligatoire')
})

const sendMail = (from, to, subject, domain, html, attachements) => {
  const data = new FormData()
  data.append('from', from)
  data.append('to', to)
  data.append('subject', subject)
  data.append('domain', domain)
  data.append('html', html)

  if (attachements && attachements.length > 0) {
    attachements.forEach((attachement, i) => {
      data.append(`file${i}`, attachement)
    })
  }

  return axios.post(
    'https://us-central1-innobelge-websites.cloudfunctions.net/emails',
    data
  )
}

const InputText = ({ name, placeholder, type }) => {
  return (
    <Field
      name={name}
      render={({ field, form: { touched, errors } }) => (
        <div>
          <Input
            inputType={type ? type : 'text'}
            placeholder={placeholder}
            iconPosition="right"
            isMaterial={false}
            className={cn(
              'email_input',
              touched[field.name] && errors[field.name] && 'error'
            )}
            id={name}
            name={name}
            {...field}
          />

          {touched[field.name] && errors[field.name] && (
            <div className="errorMessage">{errors[field.name]}</div>
          )}
        </div>
      )}
    />
  )
}

const ContactSection = ({
  sectionWrapper,
  row,
  contactForm,
  headingForm,
  secTitleWrapper,
  secHeading,
  secText,
  button,
  note,
  title,
  description,
  colornote
}) => {
  return (
    <SectionMainWrapper id="inscription">
      <Box {...sectionWrapper}>
        <Container className="containerClass">
          <Box {...secTitleWrapper}>
            <FeatureBlock
              title={<Heading {...title} />}
              description={<Text {...description} />}
            />
          </Box>
          <Box {...row}>
            <Box {...contactForm}>
              <ContactFromWrapper>
                <Formik
                  initialValues={{
                    nomEntreprise: '',
                    tvaEntreprise: '',
                    siteEntreprise: '',
                    nom: '',
                    prenom: '',
                    tel: '',
                    email: '',
                    description: '',
                    attachments: []
                  }}
                  onSubmit={(values, actions) => {
                    swal({
                      title: "Message en cours d'envoi",
                      content: <ClipLoader />,
                      typr: 'info',
                      button: false
                    })

                    const html = []
                    Object.keys(values).forEach((key, index) => {
                      if (typeof values[key] === 'string') {
                        html.push(`<h3>${key}</h3><p>${values[key]}</p>`)
                      }
                    })

                    sendMail(
                      'noreply@mobismile.be',
                      'contact@innosmile.be',
                      'Nouveau message mobismile.be',
                      'innobelge.be',
                      html.join(''),
                      values.attachments
                    )
                      .then(() => {
                        swal(
                          'Message envoyé!',
                          'Nous vous répondons dans un délai de 24h',
                          'success'
                        )
                        actions.setSubmitting(false)
                        actions.resetForm()
                      })
                      .catch(error => {
                        swal('Good job!', error, 'error')
                      })
                  }}
                  validationSchema={ContactSchema}
                  render={props => {
                    console.log('propsssss', props.values)
                    return (
                      <form onSubmit={props.handleSubmit}>
                        <Row>
                          <Col md={12}>
                            <Heading
                              {...headingForm}
                              content="Votre entreprise"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <InputText name="nomEntreprise" placeholder="Nom" />
                          </Col>
                          <Col md={6}>
                            <InputText
                              name="tvaEntreprise"
                              placeholder="Numéro TVA"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <InputText
                              name="siteEntreprise"
                              placeholder="Site Web"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Heading
                              {...headingForm}
                              content="Personne de contact"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <InputText name="nom" placeholder="Nom" />
                          </Col>
                          <Col md={6}>
                            <InputText name="prenom" placeholder="Prénom" />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <InputText name="tel" placeholder="Tél / GSM" />
                          </Col>
                          <Col md={6}>
                            <InputText name="email" placeholder="Email" />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Heading
                              {...headingForm}
                              content="Produits que vous souhaiteriez exposer"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <InputText
                              type="textarea"
                              name="description"
                              placeholder="Décrivez ici le ou les produits que vous souhaiterez faire découvrir aux consommateurs locaux"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Dropzone
                              onDrop={acceptedFiles =>
                                props.setFieldValue(
                                  'attachments',
                                  acceptedFiles
                                )
                              }
                            >
                              {({ getRootProps, getInputProps }) => (
                                <React.Fragment>
                                  <div
                                    className="dropzoneContainer"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="dropzoneIcon">
                                      <Icon icon={cloud_upload} size={64} />
                                    </div>
                                    <p>
                                      Vous pouvez cliquer ou glisser des
                                      fichiers ici afin de les ajouter au devis
                                    </p>
                                  </div>

                                  <ul className="">
                                    {props.values.attachments &&
                                      props.values.attachments.map(
                                        attachment => (
                                          <li
                                            key={
                                              attachment.name +
                                              attachment.lastModified
                                            }
                                            className=""
                                          >
                                            <Icon icon={clip} />{' '}
                                            {attachment.name}
                                          </li>
                                        )
                                      )}
                                  </ul>
                                </React.Fragment>
                              )}
                            </Dropzone>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <div className="buttonContainer">
                              <Button
                                type="submit"
                                title="Demande d'inscription en ligne"
                                {...button}
                              />
                            </div>
                          </Col>
                        </Row>
                      </form>
                    )
                  }}
                />
              </ContactFromWrapper>
            </Box>
          </Box>
        </Container>
      </Box>
    </SectionMainWrapper>
  )
}

ContactSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  contactForm: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  button: PropTypes.object,
  note: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  colornote: PropTypes.object
}

ContactSection.defaultProps = {
  sectionWrapper: {
    id: 'contact_section',
    as: 'section',
    pt: ['8px', '80px', '80px', '80px'],
    pb: ['0', '80px', '80px', '80px', '80px']
  },
  secTitleWrapper: {
    mb: ['40px', '40px', '40px'],
    p: ['0 15px', 0, 0, 0, 0]
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: `${2}`,
    letterSpacing: '0.15em',
    fontWeight: `${6}`,
    color: 'primary',
    mb: `${3}`
  },
  secHeading: {
    textAlign: 'center',
    fontSize: [`${6}`, `${8}`],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: `${0}`
  },
  row: {
    flexBox: true,
    justifyContent: 'center'
  },
  contactForm: {
    width: [1, 1, 1, 1 / 2]
  },
  headingForm: {
    mt: '25px',
    as: 'h5'
  },
  button: {
    type: 'submit',
    fontSize: `${2}`,
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: `${4}`
  },
  note: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center']
  },
  colornote: {
    fontSize: '16px',
    fontWeight: '500',
    color: 'rgb(106, 82, 253)',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center']
  },
  title: {
    content: 'Inscription en ligne',
    fontSize: ['20px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '20px',
    textAlign: ['center', 'center']
  },

  description: {
    content: 'And be the first to know when our crowdsale launches!.',
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center']
  }
}

export default ContactSection
