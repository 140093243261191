import React, { Fragment } from 'react'
import Sticky from 'react-stickynode'
import { ThemeProvider } from 'styled-components'
import { cryptoTheme } from 'common/src/theme/crypto'
import { ResetCSS } from 'common/src/assets/css/style'
import { Helmet } from 'react-helmet'
import {
  GlobalStyle,
  ContentWrapper
} from 'common/src/containers/Crypto/crypto.style'
import { DrawerProvider } from 'common/src/contexts/DrawerContext'
import Navbar from 'common/src/containers/Crypto/Navbar'
import Banner from 'common/src/containers/Crypto/BannerSection'
import BannerSlider from 'common/src/containers/Crypto/BannerSlider'
import Transactions from 'common/src/containers/Crypto/Transaction'
import ControlSections from 'common/src/containers/Crypto/ControlSection'
import ControlSectionsSecondary from 'common/src/containers/Crypto/ControlSectionsSecondary'
import TrustedProofSections from 'common/src/containers/Crypto/TrustedProof'
import ScalableSections from 'common/src/containers/Crypto/ScalableSection'
import SlideSections from 'common/src/containers/Crypto/CryptoSlides'
import BetaSections from 'common/src/containers/Crypto/BetaSection'
import Footer from 'common/src/containers/Crypto/Footer'

export default () => {
  return (
    <ThemeProvider theme={cryptoTheme}>
      <Fragment>
        <Helmet>
          <title>
            Mobismile – le lien entre producteur local et consommateur
          </title>
          <meta
            name="description"
            content="Mobismile favorise la consommation locale en créant le lien entre les producteurs locaux et le consommateur. Découvrez en plus."
          />
        </Helmet>
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Banner />
          <BannerSlider />
          <ControlSections />
          <Transactions />
          <ControlSectionsSecondary />
          <SlideSections />
          <ScalableSections />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  )
}
