import styled from 'styled-components'

const ContactFromWrapper = styled.div`
  @media (max-width: 990px) {
    padding: 0 20px;
  }
  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    button {
      width: 100%;
    }
  }

  .dropzoneContainer {
    width: 100%;
    display: block;
    background: #fff;
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    color: #797979;
    padding: 15px;
    margin: 5px 0px;
    text-align: center;
    border-color: #dadada;
    border-style: solid;
    border-width: 2px;
    border-color: #7fc6e6;
    border-radius: 10px;
    background-color: #f2f9fd;
  }

  .dropzoneIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #797979;
  }

  .error input,
  .error textarea {
    color: #e91c23 !important;
    border-color: #e91c23 !important;
  }

  .errorMessage {
    color: #e91c23;
    margin-bottom: 5px;
  }

  .email_input {
    flex-grow: 1;
    @media (max-width: 575px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
    &.is-material {
      &.is-focus {
        label {
          color: #aeb1b6;
          font-size: 12px;
        }
      }
    }

    input {
      height: 100%;
      background: #fff;
      font-size: 16px;
      font-family: inherit;
      font-weight: 500;
      color: #343d48;
      padding: 15px;
      margin: 5px 0px;
      border-color: #dadada;
      border-style: solid;
      border-width: 2px;
      border-color: #7fc6e6;
      border-radius: 10px;
      background-color: #f2f9fd;

      @media (max-width: 575px) {
      }
    }

    textarea {
      width: 100%;
      display: block;
      background: #fff;
      font-family: inherit;
      font-size: 16px;
      font-weight: 500;
      color: #343d48;
      padding: 15px;
      margin: 5px 0px;
      border-color: #dadada;
      border-style: solid;
      border-width: 2px;
      border-color: #7fc6e6;
      border-radius: 10px;
      background-color: #f2f9fd;
      resize: vertical;
      @media (max-width: 575px) {
      }
    }

    label {
      font-size: 14px;
      color: #343d48;
      font-weight: 500;
      padding-left: 10px;
      top: 5px;
    }
  }

  .field-wrapper {
    height: 100%;
  }

  .buttonContainer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button {
    border-radius: 6px;
    background-image: -moz-linear-gradient(29deg, #e91c23 0%, #ef6065 100%);
    background-image: -webkit-linear-gradient(29deg, #e91c23 0%, #ef6065 100%);
    background-image: -ms-linear-gradient(29deg, #e91c23 0%, #ef6065 100%);
    height: 60px;
    &:hover {
      background-image: -moz-linear-gradient(29deg, #e91c23 0%, #ef6065 100%);
      background-image: -webkit-linear-gradient(
        29deg,
        #e91c23 0%,
        #ef6065 100%
      );
      background-image: -ms-linear-gradient(29deg, #e91c23 0%, #ef6065 100%);
      border-color: #2796c9;
      opacity: 0.85;
    }
    @media (max-width: 990px) {
      right: 40px;
    }
    @media (max-width: 575px) {
      position: relative;
      right: 0;
      top: 0;
      height: 48px;
    }
  }
`
const SectionMainWrapper = styled.div`
  margin-top: -450px;
  z-index: 1;
  position: relative;
  @media (max-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 480px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  .containerClass {
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 94px 6px rgba(107, 83, 254, 0.1);
    padding: 80px 0;

    .contactdes {
      text-align: center;
      @media (max-width: 990px) {
        padding: 0 20px;
      }
    }
  }
`
export { SectionMainWrapper }

export default ContactFromWrapper
