import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Countdown from 'react-countdown-now'
import Box from 'reusecore/src/elements/Box'
import Fade from 'react-reveal/Fade'
import Card from 'reusecore/src/elements/Card'
import Image from 'reusecore/src/elements/Image'
import Text from 'reusecore/src/elements/Text'
import Heading from 'reusecore/src/elements/Heading'
import Button from 'reusecore/src/elements/Button'
import FeatureBlock from '../../../components/FeatureBlock'
import Container from '../../../components/UI/Container'
import { ControlWrapper } from './controlSection.style'
import ControlImage from '../../../assets/image/crypto/control.jpg'

const Completionist = () => (
  <span className="readMore">You are good to go!</span>
)

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />
  } else {
    // Render a countdown
    return (
      <div className="countPortion">
        <div className="countSingle">
          {days} <span className="countText">Days</span>
        </div>
        <div className="countSingle">
          {hours} <span className="countText">Hours</span>
        </div>
        <div className="countSingle">
          {minutes} <span className="countText">Minutes</span>
        </div>
        <div className="countSingle">
          {seconds} <span className="countText">Seconds</span>
        </div>
      </div>
    )
  }
}
const ControlSection = ({
  row,
  col,
  title,
  description,
  btnStyle,
  featureTitleStyle,
  sectionSubTitle,
  cardArea,
  readMoreTitle
}) => {
  return (
    <ControlWrapper id="control">
      <Container>
        <Box className="row" {...row}>
          <Box className="colleft" {...col} style={{ flexDirection: 'column' }}>
            <Image
              src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/mobismile/apricot-background-blossom-1028598.jpg"
              className="controlImage"
              alt="Control Section Image"
            />
            {/*
            <Image
              src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/mobismile/animals-barn-bucket-840111.jpg"
              className="controlImage"
              alt="Control Section Image"
            />
            */}
          </Box>
          <Box className="colright" {...col} {...cardArea}>
            <Text {...sectionSubTitle} />
            <FeatureBlock
              title={<Heading {...title} />}
              description={
                <React.Fragment>
                  <Heading
                    as="h3"
                    {...featureTitleStyle}
                    content="Horizon 2019 - 2022"
                  />
                  <ul>
                    <li>
                      <strong>Vague 1 :</strong> les premiers concours
                      débuteront déjà en juillet-août sur une dizaine de grandes
                      surfaces établies en Wallonie
                    </li>
                    <li>
                      <strong>Vague 2 :</strong> une expansion dans une
                      cinquantaine de grandes surfaces à Bruxelles et en
                      Wallonie est prévue pour la fin de l’année 2019
                    </li>
                    <li>
                      <strong>Vague 3 :</strong> A partir de 2020, la troisième
                      vague englobera une centaine de grandes surfaces réparties
                      à travers toute la Belgique, Flandre y compris. Les
                      artisans auront l’occasion de sélectionner les régions
                      dans lesquelles ils souhaitent être visibles.
                    </li>
                  </ul>
                  <Heading
                    as="h3"
                    {...featureTitleStyle}
                    content="Développement du réseau « bio »"
                  />
                  <Text
                    {...description}
                    content="Parallèlement aux concours organisés en grande surface, mobiSmile développe activement son réseau de magasins « bio » afin de pouvoir offrir aux producteurs locaux de nouvelles opportunités. Notre objectif est d’offrir aux producteurs locaux un accès à notre « réseau bio », et ce à partir de juin 2020."
                  />
                </React.Fragment>
              }
            />
            {/*
            <Box className="readMoreSection">
              <Text {...readMoreTitle} />
              <Link href="#">
                <a className="readMore">Read More. </a>
              </Link>
            </Box>
            <Fade up>
              <Box className="countDownSection">
                <Countdown
                  date={Date.now() + 909999999}
                  renderer={renderer}
                  completed={false}
                />
              </Box>
            </Fade>
            <Box className="countDownButton">
              <Button
                title="BUY TOKENS"
                className="countDownMainButton"
                {...btnStyle}
              />
              <Button
                title="35% Bonus"
                className="countDownDiscountButton"
                {...btnStyle}
              />
            </Box>
            */}
          </Box>
        </Box>
      </Container>
    </ControlWrapper>
  )
}

// Transactions style props
ControlSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  readMoreTitle: PropTypes.object,
  btnStyle: PropTypes.object
}

// Transactions default style
ControlSection.defaultProps = {
  // Transactions section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  // Transactions section col default style
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, 1 / 2, 1 / 2, 1 / 2, 1 / 2],
    flexBox: true,
    alignSelf: 'center'
  },

  // Transactions section title default style
  title: {
    content: 'Timing et localisation',
    fontSize: ['24px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '100%', '415px'],
    textAlign: ['left', 'left']
  },
  // Transactions section description default style
  description: {
    content:
      'Le programme mobiSmile a pour objectif de faire découvrir au consommateur final des produits locaux dont il ignorait l’existence, et ce à travers des concours organisés dans différentes grandes surfaces belges.',
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['30px', '30px', '35px', '35px', '45px'],
    textAlign: ['left', 'left'],
    maxWidth: ['100%', '100%', '100%', '100%', '430px']
  },
  sectionSubTitle: {
    content: '',
    as: 'span',
    textAlign: 'left',
    fontSize: ['16px', '16px', '18px', '20px', '20px'],
    fontFamily: 'Poppins',
    fontWeight: '600',
    lineHeight: '27px',
    color: '#525f7f',
    textAlign: ['left', 'left']
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500'
  },
  readMoreTitle: {
    content: 'Sale currently on hold. ',
    as: 'span',
    fontSize: ['18px', '18px', '20px', '20px', '20px'],
    lineHeight: ['25px', '27px', '27px', '27px', '27px'],
    fontWeight: '500',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '10px',
    textAlign: ['left', 'left']
  },
  featureTitleStyle: {
    fontSize: ['18px', '18px', '20px', '20px', '20px'],
    lineHeight: ['25px', '27px', '27px', '27px', '27px'],
    fontWeight: '500',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '10px',
    mt: '30px',
    textAlign: ['left', 'left']
  }
}

export default ControlSection
